import "./style.css";

const Sidebar = ({ adverb, hardSentence, veryHardSentence }) => {
  return (
    <div id="sidebar">
      <div id="sidebar-content">
        <div className="block hard-sentence">
          <p>{hardSentence} sentence is hard to read</p>
        </div>
        <div className="block very-hard-sentence">
          <p>{veryHardSentence} sentence is very hard to read</p>
        </div>
        <div className="block adverb">
          <p>{adverb} in this document</p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
