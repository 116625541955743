import "draft-js/dist/Draft.css";
import React, { useEffect, useRef, useState } from "react";
import { Editor, EditorState, ContentState } from "draft-js";
import SimpleDecorator from "draft-js-simpledecorator";

import "./style.css";
import initialText from "./initialText";
import Sidebar from "./subviews/sidebar";
import ADVERB_REGEX from "../../helpers/editor/adverbsList";
import SimpleNesting from "../../helpers/editor/simplenest2";

//Dificulty

const AdverbComponent = (props) => {
  return <span className={"adverb-word"}>{props.children}</span>;
};

const ReadabilityComp = (props) => {
  return (
    <span className={`sentence-${props.customProps.sentenceDificulty}`}>
      {props.children}
    </span>
  );
};

// const COLOR_REGEX = /#[0-9A-Fa-f]{6}/g;
const SENTENCE_REGEX = /(?:[.!? ]|^)([A-Z|a-z][^.!?\n]*[.!?])(?= |[A-Z]|$)/g;
const dificulty = [10, 24];
const kReadability = {
  normal: "normal",
  hard: "hard",
  veryhard: "veryhard",
};

const setReadability = (text, matchedTextLength) => {
  const splittedTextLength = text.split(" ").length;
  if (splittedTextLength < dificulty[0]) {
    return kReadability.normal;
  } else if (
    splittedTextLength >= dificulty[0] &&
    splittedTextLength < dificulty[1]
  ) {
    return kReadability.hard;
  } else if (splittedTextLength >= dificulty[1]) {
    return kReadability.veryhard;
  }
};

const sentenceChecker = (text, cb) => {
  let matchArr, sentenceDificulty;

  while ((matchArr = SENTENCE_REGEX.exec(text)) !== null) {
    let props = {};
    const start = matchArr.index;
    const end = start + matchArr[0].length;
    sentenceDificulty = setReadability(text, end - start);
    props = { customProps: { sentenceDificulty } };

    if (cb) {
      cb(start, end, props);
    }
  }
  return sentenceDificulty;
};

const adverbChecker = (text, cb) => {
  let matchArr,
    adverbCount = 0;

  while ((matchArr = ADVERB_REGEX.exec(text)) !== null) {
    adverbCount++;
    let props = {};
    const start = matchArr.index;
    const end = start + matchArr[0].length;

    if (cb) {
      cb(start, end, props);
    }
  }
  return adverbCount;
};

const setScore = (
  plainText,
  setHardSentence,
  setVeryHardSentence,
  setAdverb
) => {
  let adverbCounter = 0,
    // normalSentence = 0,
    hardSentence = 0,
    veryHardSentence = 0;
  for (let sentence of plainText) {
    const sentenceDificulty = sentenceChecker(sentence);
    switch (sentenceDificulty) {
      case kReadability.normal:
        // normalSentence++;
        break;
      case kReadability.hard:
        hardSentence++;
        break;
      case kReadability.veryhard:
        veryHardSentence++;
        break;
      default:
        break;
    }
  }

  console.log(adverbChecker(plainText));
  adverbCounter = adverbChecker(plainText);
  setAdverb(adverbCounter);
  setHardSentence(hardSentence);
  setVeryHardSentence(veryHardSentence);
};

const decorators = () =>
  new SimpleNesting([
    new SimpleDecorator(
      function strategy(contentBlock, callback, contentState) {
        const text = contentBlock.getText();
        sentenceChecker(text, callback);
      },
      function component(props) {
        return <ReadabilityComp {...props} />;
      }
    ),
    new SimpleDecorator(
      function strategy(contentBlock, callback, contentState) {
        const text = contentBlock.getText();
        adverbChecker(text, callback);
      },
      function component(props) {
        return <AdverbComponent {...props} />;
      }
    ),
  ]);

const Main = () => {
  const initialState = ContentState.createFromText(initialText);

  const [adverb, setAdverb] = useState(0);
  const [hardSentence, setHardSentence] = useState(0);
  const [veryHardSentence, setVeryHardSentence] = useState(0);
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      initialState,
      decorators(setHardSentence, setVeryHardSentence)
    )
  );

  const editor = useRef(null);

  useEffect(() => {
    const plainText = editorState
      .getCurrentContent()
      .getPlainText("")
      .split("\u000A");

    setScore(plainText, setHardSentence, setVeryHardSentence, setAdverb);
  }, [editorState]);

  return (
    <div id="main">
      <Sidebar
        hardSentence={hardSentence}
        veryHardSentence={veryHardSentence}
        adverb={adverb}
      />

      <div className="editor-container">
        <div className="editor-wrapper">
          <div className="editor-content">
            <Editor
              placeholder={"Type something here..."}
              ref={editor}
              editorState={editorState}
              onChange={(newState) => {
                setEditorState(newState);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
