const text = `Good Words! 

Current test rules are the following: 
-from 0 to 10 words it will be a normal sentence
-from 10 to 24 words it will be a hard sentence
-more than 24 words it will be a very hard sentence
-every sentence needs to be ending with punctiation to be recognized as a sentence
    
Example of a normal sentence.
Example of a hard sentence shown here as we speak.
Example of a hard sentence shown here as we speak with more words bla bla bla bla bla bla bla bla bla bla bla.
And we have adverb lists as well.
Perks: animations between readability change 😋
    `;
export default text;
