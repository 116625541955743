import { Main } from "Pages";

import "./App.css";

function App() {
  return (
    <div id="App">
      <Main />
    </div>
  );
}

export default App;
